
import http from '../axios'
import {api_qyk} from '../config'
// 主营
export const $apiMainType =()=>http.post( api_qyk +'/main/type')

// 入驻企业库
export const $apiSettleInApply = params =>http.post(api_qyk +'/settle/in/apply',params)

//验证是否重复提交 
export const $apiCheckBindCompany =params =>http.post(api_qyk +'/check/bind/company',params)

// 入驻审核状态
export const $apiFlairAuthState = params=>http.post(api_qyk +'/flair/auth/state',params)

// 企业入住地区
export const $apiRegion = params=>http.post(api_qyk +'/region',params)